export const environment = {
  apiUrl: 'https://api.riverisland.28one-dev.com',
  ENV: "dev",
  sentry: null,
  dataName: 'riverisland',
  clientName: 'riverisland',
  enableReset: false,
  production: false,
  uiClienId: 'a2cb7ab5-5b7a-4d43-8dcb-c364369697a8',
  redirectUrl: 'https://riverisland.28one-dev.com',
  navBar: {
    leftImage: 'assets/icons/riverisland.png',
    leftImageHeight: 30,
    menuSetting: '[{"name":"reports","default":true,"report-id":"ca1725e3-4dca-4823-b72f-bff8d0deb8c7","page-name":"ReportSectionf71766f04794c71c8443"},{"name":"dashboards","default":false,"report-id":"ca1725e3-4dca-4823-b72f-bff8d0deb8c7","page-name":"ReportSection791bcdeec022f2c08cd5"},{"name":"management report","default":false,"report-id":"ca1725e3-4dca-4823-b72f-bff8d0deb8c7","page-name":"ReportSectiona63adda1d0a45a065bae"},{"name":"inspection","default":false}]',
    roleFilter: '{"inspection":[1,2,8]}',
    showAdmin: true,
    asDropdown: false,
  },
  sideBar: '{"po-selection":{"show":true},"packaging":{"show":false},"downloads":{"show":false},"inspection-plan":{"title":"AQL Inspection Plan"},"inspections":{"icon":"t-shirt.svg"}}',
  import: '{"select-centre":true,"select-group":false,"select-inspection-type":true,"select-inspection-level":false,"title":"UPLOAD PRODUCT READY TO INSPECT"}',
  uploadSizeChart: '{"title":"PRODUCT DETAILS","product-code-title":"Style Code","add-title":"ADD PRODUCT","delete-title":"DELETE PRODUCT","need-upload":true}',
  clientInspection: '{"date-range-minus":10,"result-filter":"Result","consol-filter":"Consol. Centre","division-filter":"Division","colour-filter":"","department-list":"Department","po-number":"PO Number","table":[{"name":"Insp. Finished","key":"inspection_date","case":"name"},{"name":"Loc.","type":"number","key":"UDC_id","case":"normal"},{"name":"Sub.","type":"number","key":"inspection_number","case":"normal"},{"name":"Style Code","key":"style_code","case":"normal"},{"name":"Colour","key":"colour","case":"name"},{"name":"Season","key":"season_code","case":"normal"},{"name":"PO Numbers","key":"client_pos","case":"normal"},{"name":"Result","key":"result","case":"result"},{"name":"RI Decision","key":"client_decision","case":"client-decision"},{"name":"Submit","title":"Accept Changes","case":"client-decision-submit"},{"name":"Full Report","case":"file","type":"pdf"},{"name":"Summary Report","case":"summary_file","type":"pdf"},{"name":"Version History","case":"report_versions"},{"name":"Department","key":"department","case":"name"},{"name":"Vendor","key":"supplier_name","case":"name"},{"name":"Factory","key":"factory_name","case":"name"},{"name":"Lot Size","key":"order_quantity","case":"name"},{"name":"Sample Size","key":"inspection_quantity","case":"name"},{"name":"Total Defects","key":"defect_quantity","case":"name"},{"name":"Critical Defects","key":"total_critical","case":"name"},{"name":"A","key":"A","case":"name"},{"name":"B","key":"B","case":"name"},{"name":"C","key":"C","case":"name"},{"name":"D","key":"D","case":"name"},{"name":"E","key":"E","case":"name"},{"name":"F","key":"F","case":"name"},{"name":"G","key":"G","case":"name"},{"name":"H","key":"H","case":"name"},{"name":"I","key":"I","case":"name"},{"name":"J","key":"J","case":"name"},{"name":"K","key":"K","case":"name"},{"name":"L","key":"L","case":"name"},{"name":"M","key":"M","case":"name"}]}',
  selectInspection: '{"date-title":"Ready Date","select-centre":true,"date-range-minus":1,"date-range-plus":0,"show-open-order-filter":false,"table":[{"name":"Arrived","key":"arrived_consol_date","case":"name"},{"name":"Size Chart","type":"number","key":"size_chart_YN","case":"normal"},{"name":"Insp. Type","key":"inspection_name","case":"normal"},{"name":"Insp.","type":"number","key":"inspection_number","case":"normal"},{"name":"Product","key":"style_code","case":"normal"},{"name":"TOT. QTY.","type":"number","key":"order_quantity","case":"normal"},{"name":"AQL","key":"select_for_AQL","case":"check"},{"name":"Inspection Date","key":"inspection_date","case":"select_date"},{"name":"Supplier Name","key":"supplier_name","case":"name"},{"name":"PO Numbers","key":"client_pos","case":"normal","empty":true},{"name":"Action","case":"insp-action"}]}',
  aqlPlan: '{"date-range-plus":0,"table":[{"name":"AQL","key":"qcdata_select_for_AQL","case":"select_unselect","show":false},{"name":"Insp. Type","key":"inspection_name","case":"name","show":true},{"name":"Insp. Date","key":"inspection_date","case":"name","show":true},{"name":"Loc.","type":"number","key":"UDC_id","case":"normal","show":true},{"name":"Insp.","type":"number","key":"inspection_number","case":"normal","show":true},{"name":"Supplier Name","key":"supplier_name","case":"name","show":true},{"name":"Season","key":"season_code","case":"normal","show":true},{"name":"PO Numbers","key":"client_pos","case":"normal","show":true},{"name":"STYLE","key":"style_code","case":"normal","show":true},{"name":"Description","key":"style_name","case":"name","show":true},{"name":"Colour","key":"colour","case":"name","show":true},{"name":"PO QTY.","type":"number","key":"order_quantity","case":"normal","show":true},{"name":"Level","type":"number","key":"sample_level","case":"level","show":true},{"name":"INSP. Qty.","type":"number","key":"inspection_quantity","case":"normal","show":true},{"name":"SUB.","type":"number","key":"inspection_number","case":"normal","show":false},{"name":"AQL Maj<","type":"number","key":"allowed_defects_maj","case":"normal","show":true},{"name":"AQL Min<","type":"number","key":"allowed_defects_min","case":"normal","show":true},{"name":"Tot. Defects","type":"number","key":"count_defects","case":"normal","show":true},{"name":"Current Status","key":"status_EN","case":"normal","show":true},{"name":"Result","key":"result","case":"result","show":true},{"name":"Inspector Name","key":"Inspector","case":"normal","show":true},{"name":"Customer","key":"customer_name","case":"normal","show":false}],"select-centre":true,"show-color":true,"title":"AQL Inspection Plan","show-po-number":true,"date-range-minus":1,"show-petek-number":false,"style-code":"Style Code","status-filter":"Status"}',
  aqlReport: '{"show-close-btn":false,"show-max-allowed":true,"info-fields":[{"title":"Product Code","field":"style_code"},{"title":"Product Name","field":"style_name"},{"title":"Colour","field":"colour"},{"title":"Supplier","field":"supplier_name"},{"title":"PO Number","field":"PO_Nos"},{"title":"Order Size","field":"Order_Size"},{"title":"Submission","field":"inspection_number"},{"title":"Inspection Level","field":"sample_level"},{"title":"Sample Qty.","field":"inspection_quantity"}],"show-result":true,"show-summary-inspection-report":true,"title":"AQL Inspection Report","major":"Major","critical":"Critical","show-centre":false,"show-inspection-date":false,"show-customer-name":false,"minor":"Minor","show-inspection-type":false,"show-inspection-id":true}',
  mainPage: {"redirect-urls":[]},
};